define('ember-intl/helpers/format-relative', ['exports', 'ember', 'ember-intl/helpers/-format-base'], function (exports, _ember, _emberIntlHelpersFormatBase) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var runBind = _ember['default'].run.bind;

  exports['default'] = (0, _emberIntlHelpersFormatBase['default'])('relative').extend({
    compute: function compute(value, hash) {
      this.clearTimer();

      if (hash && typeof hash.interval !== 'undefined') {
        this.timer = setTimeout(runBind(this, this.recompute), parseInt(hash.interval, 10));
      }

      return this._super.apply(this, arguments);
    },

    clearTimer: function clearTimer() {
      clearTimeout(this.timer);
    },

    destroy: function destroy() {
      this._super.apply(this, arguments);

      this.clearTimer();
    }
  });
});