define('ember-intl/adapters/default', ['exports', 'ember', 'ember-intl/models/translation', 'ember-intl/utils/normalize-locale'], function (exports, _ember, _emberIntlModelsTranslation, _emberIntlUtilsNormalizeLocale) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var assert = _ember['default'].assert;
  var emberArray = _ember['default'].A;
  var getOwner = _ember['default'].getOwner;

  var DefaultIntlAdapter = _ember['default'].Object.extend({
    seen: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.seen = emberArray();
    },

    normalizeLocaleName: function normalizeLocaleName(localeName) {
      assert('Locale name must be a string', typeof localeName === 'string');

      return (0, _emberIntlUtilsNormalizeLocale['default'])(localeName);
    },

    lookupLocale: function lookupLocale(localeName) {
      return this.seen.findBy('localeName', this.normalizeLocaleName(localeName));
    },

    localeFactory: function localeFactory(localeName) {
      var owner = getOwner(this);
      var normalizedLocale = this.normalizeLocaleName(localeName);
      var lookupName = 'ember-intl@translation:' + normalizedLocale;
      var model = owner.lookup(lookupName);

      if (model) {
        return model;
      }

      var Klass = owner._lookupFactory('model:ember-intl-translation') || _emberIntlModelsTranslation['default'];
      var ModelKlass = Klass.extend();

      Object.defineProperty(ModelKlass.proto(), 'localeName', {
        writable: false,
        enumerable: true,
        value: normalizedLocale
      });

      owner.register(lookupName, ModelKlass);
      model = owner.lookup(lookupName);
      this.seen.pushObject(model);

      return model;
    },

    has: function has(localeName, translationKey) {
      var model = this.lookupLocale(localeName);

      return model && model.has(translationKey);
    },

    lookup: function lookup(localeNames, translationKey) {
      for (var i = 0; i < localeNames.length; i++) {
        var localeName = localeNames[i];
        var model = this.lookupLocale(localeName);

        if (model && model.has(translationKey)) {
          return model.getValue(translationKey);
        }
      }
    },

    translationsFor: function translationsFor() {
      return this.localeFactory.apply(this, arguments);
    },

    findTranslationByKey: function findTranslationByKey() {
      return this.lookup.apply(this, arguments);
    }
  });

  exports['default'] = DefaultIntlAdapter;
});