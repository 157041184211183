define("ember-data/-private/features", ["exports"], function (exports) {
  "use strict";

  exports["default"] = isEnabled;

  function isEnabled() {
    var _Ember$FEATURES;

    return (_Ember$FEATURES = Ember.FEATURES).isEnabled.apply(_Ember$FEATURES, arguments);
  }
});