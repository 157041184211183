define('ember-intl-cp-validations/validators/messages', ['exports', 'ember', 'ember-cp-validations/validators/messages'], function (exports, _ember, _emberCpValidationsValidatorsMessages) {
  'use strict';

  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  var isEmpty = _ember['default'].isEmpty;

  exports['default'] = _emberCpValidationsValidatorsMessages['default'].extend({
    intl: _ember['default'].inject.service(),
    warn: _ember['default'].warn,
    prefix: 'errors',

    init: function init() {
      this._super.apply(this, arguments);

      var owner = getOwner(this);

      if (owner) {
        this._config = owner.resolveRegistration('config:environment') || {};
      }
    },

    _warn: function _warn(msg, meta) {
      if (this._config && get(this._config, 'intl_cp_validations.suppressWarnings')) {
        return;
      }

      this.warn(msg, meta);
    },

    getDescriptionFor: function getDescriptionFor(attribute) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var intl = get(this, 'intl');
      var key = get(this, 'prefix') + '.description';
      var foundCustom = undefined;

      if (!isEmpty(options.descriptionKey)) {
        key = options.descriptionKey;
        foundCustom = true;
      } else if (!isEmpty(options.description)) {
        return options.description;
      }

      if (intl) {
        if (intl.exists(key)) {
          return intl.t(key, options);
        } else if (foundCustom) {
          this._warn('Custom descriptionKey \'' + key + '\' provided but does not exist in intl translations.', false, {
            id: 'ember-intl-cp-validations-missing-custom-key'
          });
        }
      }

      return this._super.apply(this, arguments);
    },

    getMessageFor: function getMessageFor(type) {
      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      var key = get(options, 'messageKey') || get(this, 'prefix') + '.' + type;
      var intl = get(this, 'intl');

      if (intl && intl.exists(key)) {
        return this.formatMessage(intl.t(key, options));
      }

      this._warn('[ember-intl-cp-validations] Missing translation for validation key: ' + key + '\nhttp://offirgolan.github.io/ember-cp-validations/docs/messages/index.html', false, {
        id: 'ember-intl-cp-validations-missing-translation'
      });

      return this._super.apply(this, arguments);
    }
  });
});