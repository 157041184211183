define("ember-data/-private/system/normalize-model-name", ["exports"], function (exports) {

  // All modelNames are dasherized internally. Changing this function may
  // require changes to other normalization hooks (such as typeForRoot).

  /**
   This method normalizes a modelName into the format Ember Data uses
   internally.
  
    @method normalizeModelName
    @public
    @param {String} modelName
    @return {String} normalizedModelName
    @for DS
  */
  "use strict";

  exports["default"] = normalizeModelName;

  function normalizeModelName(modelName) {
    return Ember.String.dasherize(modelName);
  }
});