define('ember-intl/helpers/format-html-message', ['exports', 'ember-intl/helpers/-format-base', 'ember-intl/helpers/format-message'], function (exports, _emberIntlHelpersFormatBase, _emberIntlHelpersFormatMessage) {
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  exports['default'] = (0, _emberIntlHelpersFormatBase['default'])('html-message').extend({
    getValue: _emberIntlHelpersFormatMessage.getValue
  });
});