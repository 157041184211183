define('ember-cli-daterangepicker/components/date-range-picker', ['exports', 'ember', 'moment', 'ember-cli-daterangepicker/templates/components/date-range-picker'], function (exports, _ember, _moment, _emberCliDaterangepickerTemplatesComponentsDateRangePicker) {
  'use strict';

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }return target;
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var run = _ember['default'].run;
  var isEmpty = _ember['default'].isEmpty;
  var computed = _ember['default'].computed;

  var noop = function noop() {};

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliDaterangepickerTemplatesComponentsDateRangePicker['default'],
    classNames: ['form-group'],
    attributeBindings: ['start', 'end', 'serverFormat'],
    start: undefined,
    end: undefined,
    minDate: undefined,
    maxDate: undefined,
    timePicker: false,
    timePicker24Hour: false,
    timePickerSeconds: false,
    timePickerIncrement: undefined,
    showWeekNumbers: false,
    showDropdowns: false,
    linkedCalendars: false,
    datelimit: false,
    parentEl: 'body',
    format: 'MMM D, YYYY',
    serverFormat: 'YYYY-MM-DD',
    rangeText: computed('start', 'end', function () {
      var format = this.get('format');
      var serverFormat = this.get('serverFormat');
      var start = this.get('start');
      var end = this.get('end');
      if (!isEmpty(start) && !isEmpty(end)) {
        return (0, _moment['default'])(start, serverFormat).format(format) + this.get('separator') + (0, _moment['default'])(end, serverFormat).format(format);
      }
      return '';
    }),
    opens: null,
    drops: null,
    separator: ' - ',
    singleDatePicker: false,
    placeholder: null,
    buttonClasses: ['btn'],
    applyClass: null,
    cancelClass: null,
    ranges: {
      'Today': [(0, _moment['default'])(), (0, _moment['default'])()],
      'Yesterday': [(0, _moment['default'])().subtract(1, 'days'), (0, _moment['default'])().subtract(1, 'days')],
      'Last 7 Days': [(0, _moment['default'])().subtract(6, 'days'), (0, _moment['default'])()],
      'Last 30 Days': [(0, _moment['default'])().subtract(29, 'days'), (0, _moment['default'])()],
      'This Month': [(0, _moment['default'])().startOf('month'), (0, _moment['default'])().endOf('month')],
      'Last Month': [(0, _moment['default'])().subtract(1, 'month').startOf('month'), (0, _moment['default'])().subtract(1, 'month').endOf('month')]
    },
    daysOfWeek: _moment['default'].weekdaysMin(),
    monthNames: _moment['default'].monthsShort(),
    removeDropdownOnDestroy: false,
    cancelLabel: 'Cancel',
    applyLabel: 'Apply',
    customRangeLabel: 'Custom Range',
    showCustomRangeLabel: false,
    fromLabel: 'From',
    toLabel: 'To',
    hideAction: null,
    applyAction: null,
    cancelAction: null,
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: false,
    context: undefined,
    firstDay: 0,
    isInvalidDate: noop,
    isCustomDate: noop,

    // Init the dropdown when the component is added to the DOM
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setupPicker();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.setupPicker();
    },

    // Remove the hidden dropdown when this component is destroyed
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      run.cancel(this._setupTimer);

      if (this.get('removeDropdownOnDestroy')) {
        _ember['default'].$('.daterangepicker').remove();
      }
    },

    getOptions: function getOptions() {
      var momentStartDate = (0, _moment['default'])(this.get('start'), this.get('serverFormat'));
      var momentEndDate = (0, _moment['default'])(this.get('end'), this.get('serverFormat'));
      var startDate = momentStartDate.isValid() ? momentStartDate : undefined;
      var endDate = momentEndDate.isValid() ? momentEndDate : undefined;

      var momentMinDate = (0, _moment['default'])(this.get('minDate'), this.get('serverFormat'));
      var momentMaxDate = (0, _moment['default'])(this.get('maxDate'), this.get('serverFormat'));
      var minDate = momentMinDate.isValid() ? momentMinDate : undefined;
      var maxDate = momentMaxDate.isValid() ? momentMaxDate : undefined;

      var options = this.getProperties('isInvalidDate', 'isCustomDate', 'alwaysShowCalendars', 'autoUpdateInput', 'autoApply', 'timePicker', 'buttonClasses', 'applyClass', 'cancelClass', 'singleDatePicker', 'drops', 'opens', 'timePicker24Hour', 'timePickerSeconds', 'timePickerIncrement', 'showWeekNumbers', 'showDropdowns', 'linkedCalendars', 'dateLimit', 'parentEl');

      var localeOptions = this.getProperties('applyLabel', 'cancelLabel', 'customRangeLabel', 'showCustomRangeLabel', 'fromLabel', 'toLabel', 'format', 'firstDay', 'daysOfWeek', 'monthNames', 'separator');

      var defaultOptions = {
        locale: localeOptions,
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        maxDate: maxDate
      };

      if (!this.get('singleDatePicker')) {
        options.ranges = this.get('ranges');
      }

      return _extends({}, options, defaultOptions);
    },

    setupPicker: function setupPicker() {
      run.cancel(this._setupTimer);
      this._setupTimer = run.scheduleOnce('afterRender', this, this._setupPicker);
    },

    _setupPicker: function _setupPicker() {
      this.$('.daterangepicker-input').daterangepicker(this.getOptions());
      this.attachPickerEvents();
    },

    attachPickerEvents: function attachPickerEvents() {
      var _this = this;

      this.$('.daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        _this.handleDateRangePickerEvent('applyAction', picker);
      });

      this.$('.daterangepicker-input').on('hide.daterangepicker', function (ev, picker) {
        _this.handleDateRangePickerEvent('hideAction', picker);
      });

      this.$('.daterangepicker-input').on('cancel.daterangepicker', function () {
        _this.handleDateRangePickerEvent('cancelAction', undefined, true);
      });
    },

    handleDateRangePickerEvent: function handleDateRangePickerEvent(actionName, picker) {
      var isCancel = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var action = this.get(actionName);
      var attrs = {};

      if (!isCancel) {
        var start = picker.startDate.format(this.get('serverFormat'));
        var end = picker.endDate.format(this.get('serverFormat'));
        attrs = { start: start, end: end };
      }

      if (action) {
        _ember['default'].assert(actionName + ' for date-range-picker must be a function', typeof action === 'function');
        this.sendAction.apply(this, [actionName].concat(_toConsumableArray(attrs)));
      } else {
        if (!this.isDestroyed) {
          this.setProperties(attrs);
        }
      }
    }
  });
});