define('ember-intl/helpers/format-message', ['exports', 'ember', 'ember-intl/helpers/-format-base', 'ember-intl/helpers/l'], function (exports, _ember, _emberIntlHelpersFormatBase, _emberIntlHelpersL) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports.getValue = getValue;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var get = _ember['default'].get;
  var assert = _ember['default'].assert;

  function getValue(_ref, _ref3) {
    var _ref2 = _slicedToArray(_ref, 1);

    var key = _ref2[0];
    var allowEmpty = _ref3.allowEmpty;
    var optionalLocale = _ref3.locale;

    if (key && key instanceof _emberIntlHelpersL.LiteralWrapper) {
      return key.value;
    }

    assert('[ember-intl] translation lookup attempted but no translation key was provided.', key);

    return get(this, 'intl').lookup(key, optionalLocale, {
      resilient: allowEmpty
    });
  }

  exports['default'] = (0, _emberIntlHelpersFormatBase['default'])('message').extend({
    getValue: getValue
  });
});